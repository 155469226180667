<template>
  <div v-if="$store.getters.isPrehledCiselPageAllowed" class="pl-2 pr-2 h-max-content">
    <div class="text-left button-line pl-1">
      <prehled-cisel-tab-buttons :tabName="tabName"/>
    </div>
    <div class="text-left filter-line d-flex">
      <span class="font-weight-bold pr-2 fs-17px pl-1">Filtry:</span>
      <b-form-select
          name="object"
          v-model="year"
          :options="getYears()"
          class="form-select form-select-sm w-120px ml-3"
          size="sm"
          @change="getPrehledCiselData()"
      />
    </div>

    <obchod-tab
        v-if="isCurrentTab('obchod')"
        :info="info"
        :year="year"
        :returnTrClass="returnTrClass"
        :getMonthValueCommon="getMonthValueCommon"
        :getYearValueCommon="getYearValueCommon"
        :getMonthStyle="getMonthStyle"
    />
    <projekce-tab
        v-else-if="isCurrentTab('projekce')"
        :info="info"
        :year="year"
        :returnTrClass="returnTrClass"
        :getMonthValueCommon="getMonthValueCommon"
        :getYearValueCommon="getYearValueCommon"
        :getMonthStyle="getMonthStyle"
    />
    <realizace-tab
        v-else-if="isCurrentTab('realizace')"
        :info="info"
        :year="year"
        :isLoading="isLoading"
        :returnTrClass="returnTrClass"
        :getMonthValueCommon="getMonthValueCommon"
        :getYearValueCommon="getYearValueCommon"
        :getMonthStyle="getMonthStyle"
    />
  </div>
</template>

<script>
import ObchodTab from "@/views/PrehledCisel/ObchodTab.vue";
import ProjekceTab from "@/views/PrehledCisel/ProjekceTab.vue";
import RealizaceTab from "@/views/PrehledCisel/RealizaceTab.vue";
import axios from "axios";
import PrehledCiselTabButtons from "@/views/PrehledCisel/PrehledCiselTabButtons.vue";
import moment from "moment/moment";
import {TYPE_OF_WORKS_OBCHOD, TYPE_OF_WORKS_PROJEKCE, TYPE_OF_WORKS_REALIZACE} from "@/constants/form.constants";
import _ from "lodash";

const curMonth = moment().month();
const curYear = moment().year();

const getMonthColumnInitInfo = (typeOfWorks) => {
  return typeOfWorks.map(work => {
        let monthColumn = {};
        (new Array(12).fill(null)).forEach((el, index) => _.set(monthColumn, `month_${index + 1}`, null));
        return {typeOfWork: {key: work.key, text: work.text, class: work.class || ''}, ...monthColumn};
      }
  )
}

const typeOfWorksByTab = {
  'obchod': TYPE_OF_WORKS_OBCHOD,
  'projekce': TYPE_OF_WORKS_PROJEKCE,
  'realizace': TYPE_OF_WORKS_REALIZACE,
};

export default {
  computed: {},
  components: {PrehledCiselTabButtons, RealizaceTab, ProjekceTab, ObchodTab},
  props: {
    tabName: {type: String, required: true},
  },
  data() {
    return {
      year: curYear,
      isLoading: false,
      info: [],
    };
  },
  methods: {
    isCurrentTab(tabName) {
      return this.tabName === tabName;
    },
    getYears() {
      const years = [];
      for (let y = curYear; y >= 2020; y -= 1) {
        years.push(y);
      }
      return years;
    },
    async getPrehledCiselData() {
      try {
        this.isLoading = true;
        this.info = getMonthColumnInitInfo(typeOfWorksByTab[this.tabName])
        const matchInfo = (info, data) => {
          info.yearTotal = data.yearTotal;
          data.months?.forEach(month => {
            let key = Object.keys(month);
            info[key] = month[key];
          })
        }

        const url = `/prehledCisel/getPrehledCiselData`;
        const {data} = await axios.get(url, {params: {year: this.year, tab: this.tabName}});

        const getMatchInfo = (info, dataKey) => {
          const indx = info.findIndex(i => i.typeOfWork.key === dataKey);
          if (indx !== -1) {
            matchInfo(info[indx], data?.data[dataKey]);
          }
        }

        for (const dataKey in data?.data) {
          getMatchInfo(this.info, dataKey);
        }
      } catch (e) {
        console.error('getPrehledCiselData', e.response?.data);
      } finally {
        this.isLoading = false;
      }
    },
    returnTrClass(elem) {
      switch (elem.typeOfWork.key) {
        case 'noveZakazkyRDBD':
        case 'lineRD':
          return 'tr-color-1 tdPaddingZero';
        case 'dokonceniRealizaceRDBD':
        case 'lineBD':
          return 'tr-color-3 tdPaddingZero';
        case 'instalace':
          return 'tr-color-1 tdPaddingZero';
        case 'lineFirmy':
          return 'tr-color-2 tdPaddingZero';
        case 'materialChybi':
          return 'tr-color-3 tdPaddingZero';
        case 'materialNaSklade':
          return 'tr-color-2 tdPaddingZero';
      }
      return 'tdPaddingHalf';
    },
    getMonthValueCommon(item, monthKey, noValues = [], isInfoLine) {
      const key = item.typeOfWork.key;
      if (noValues.includes(key)) return '';

      if (isInfoLine(key)) return '';
      const numMonth = monthKey.split('_')[1];
      if ((numMonth > curMonth + 1) && this.year === curYear) return '';
      const value = _.get(item, `${monthKey}`);
      return value || 0;
    },
    getYearValueCommon(item, noValues = [], isInfoLine) {
      const key = item.typeOfWork.key;
      if (noValues.includes(key)) return '';

      if (isInfoLine(key)) return '';
      return item.yearTotal || 0;
    },
    getMonthStyle(index, year) {
      if (curYear !== year) return '';
      if (index < curMonth) return 'overview-col-old';
      else if (index === curMonth) return 'overview-col-curr';
      else return 'overview-col-next';
    },
  },
  async mounted() {
    await this.getPrehledCiselData();
  },
};
</script>

<style>
.tr-color-1 > td {background-color: #fde9d9 !important;}
.tr-color-2 > td {background-color: #b7dee8 !important;}
.tr-color-3 > td {background-color: #ffe4e1 !important;}
.tdPaddingZero > td {padding-bottom: 0; padding-top: 0;}
.tdPaddingHalf > td {padding-bottom: 0.5rem; padding-top: 0.5rem;}
</style>
