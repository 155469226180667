var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"mh-screen",attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('b-table',{staticClass:"border-bottom",attrs:{"sticky-header":"calc(100vh - 14.5rem)","bordered":"","responsive":"","hover":"","no-border-collapse":"","tbody-tr-class":_vm.returnTrClass,"items":_vm.info,"fields":_vm.getHeaders()},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var field = ref.field;
return [(field.label)?_c('span',{domProps:{"innerHTML":_vm._s(field.label)}}):_vm._e()]}},{key:"cell(typeOfWork)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left text-nowrap",class:item.typeOfWork.class},[_vm._v(" "+_vm._s(item.typeOfWork.text)+" ")])]}},{key:"cell(month_1)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_1')))])]}},{key:"cell(month_2)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_2')))])]}},{key:"cell(month_3)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_3')))])]}},{key:"cell(month_4)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_4')))])]}},{key:"cell(month_5)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_5')))])]}},{key:"cell(month_6)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_6')))])]}},{key:"cell(month_7)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_7')))])]}},{key:"cell(month_8)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_8')))])]}},{key:"cell(month_9)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_9')))])]}},{key:"cell(month_10)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_10')))])]}},{key:"cell(month_11)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_11')))])]}},{key:"cell(month_12)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceMonthValue(item, 'month_12')))])]}},{key:"cell(yearTotal)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.getProjekceYearTotalValue(item)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }