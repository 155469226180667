<template>
  <div>
    <b-btn
        href="/prehled-cisel/obchod"
        :variant="getButtonVariant('obchod')"
        class=""
    >
      Obchod
    </b-btn>
    <b-btn
        href="/prehled-cisel/projekce"
        :variant="getButtonVariant('projekce')"
        class="ml-4"
    >
      Projekce
    </b-btn>
    <b-btn
        href="/prehled-cisel/realizace"
        :variant="getButtonVariant('realizace')"
        class="ml-4"
    >
      Realizace
    </b-btn>
  </div>
</template>
<script>

export default {
  name: 'prehled-cisel-tab-buttons',
  props: {
    tabName: {type: String, required: true},
  },
  methods: {
    getButtonVariant(btnName) {
      if (btnName === this.tabName) return 'primary';
      return 'outline-primary';
    },
  }
}
</script>