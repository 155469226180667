<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <b-table
        sticky-header="calc(100vh - 14.5rem)"
        bordered
        responsive
        hover
        no-border-collapse
        :tbody-tr-class="returnTrClass"
        :items="info"
        :fields="getHeaders()"
        class="border-bottom"
    >
      <template #head()="{ field }">
        <span v-html="field.label"></span>
      </template>
      <template #cell(typeOfWork)="{ item }" class="color1">
        <div class="text-left text-nowrap" :class="item.typeOfWork.class">
          {{item.typeOfWork.text}}
        </div>
      </template>
      <template #cell(month_1)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_1') }}</span>
      </template>
      <template #cell(month_2)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_2') }}</span>
      </template>
      <template #cell(month_3)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_3') }}</span>
      </template>
      <template #cell(month_4)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_4') }}</span>
      </template>
      <template #cell(month_5)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_5') }}</span>
      </template>
      <template #cell(month_6)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_6') }}</span>
      </template>
      <template #cell(month_7)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_7') }}</span>
      </template>
      <template #cell(month_8)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_8') }}</span>
      </template>
      <template #cell(month_9)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_9') }}</span>
      </template>
      <template #cell(month_10)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_10') }}</span>
      </template>
      <template #cell(month_11)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_11') }}</span>
      </template>
      <template #cell(month_12)="{ item }">
        <span class="text-nowrap">{{ getObchodMonthValue(item, 'month_12') }}</span>
      </template>
      <template #cell(yearTotal)="{ item }">
        <span class="text-nowrap">{{ getObchodYearTotalValue(item) }}</span>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import {MONTH_NAMES} from "@/constants/form.constants";

export default {
  props: {
    info: {type: Array, required: true},
    year: {type: Number, required: true},
    returnTrClass: {type: Function, required: true},
    getMonthValueCommon: {type: Function, required: true},
    getYearValueCommon: {type: Function, required: true},
    getMonthStyle: {type: Function, required: true},
  },
  components: {},
  computed: {},
  data() {
    return {
      isLoading: false,
      headers: [],
      currentTab: 'obchod',
    };
  },
  methods: {
    getHeaders() {
      return [
        {label: ``, key: `typeOfWork`, class: 'w-350px', stickyColumn: true },
        ...this.getMonthYearHeaders(this.year),
        {label: `rok ${this.year}`, key: `yearTotal`, class: 'overview-col-year'},
      ]
    },
    getObchodMonthValue(item, monthKey) {
      const noValues = [];
      return this.getMonthValueCommon(item, monthKey, noValues, this.isInfoLine);
    },
    getObchodYearTotalValue(item) {
      const noValues = [];
      return this.getYearValueCommon(item, noValues, this.isInfoLine);
    },
    isInfoLine(key) {
      return ['noveZakazkyRDBD', 'dokonceniRealizaceRDBD', 'lineRD', 'lineBD', 'lineFirmy'].includes(key);
    },
    getMonthYearHeaders(year) {
      return MONTH_NAMES.map((monthName, index) => {
        return { label: `${monthName}<br>${year}`, key: `month_${index + 1}`, class: this.getMonthStyle(index, year) };
      });
    },
  },
  async mounted() {

  },
};
</script>

<style>

</style>
